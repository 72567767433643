<!--
 * @Author: 刘格优
 * @Date: 2019-12-23 15:02:56
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-26 14:28:06
 -->

<template>
  <div class="acontent">
    <div class="boxleft">
      <ul>
        <li v-for="(flitem, flindex) in fllist"
            :key="flindex"
            @click="changetab(flitem.code,flindex)">
          <div :class="flitem.ischoose?'red':''"
               :style="flitem.ischoose?`color:${colorprimary}`:''">
            <span :class="flitem.ischoose?'orange':'noneorange'"
                  :style="flitem.ischoose?`backgroundColor:${colorprimary}`:'noneorange'"></span>
            {{flitem.lable}}
          </div>
        </li>
      </ul>
    </div>
    <div v-show="!isNull"
         class="scrollbox">
      <md-scroll-view ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMorechange">
        <div class="wzcontent">
          <ul class="list-container aul"
              id="content">
            <li class="ali"
                v-for="(item, index) in wzlist"
                :key="index">
              <div class="card"
                   @click="read(item,index)"
                   v-if="item.status == 0">
                <div class="card-name">
                  <span class="redround"
                        v-if="item.status == 0"></span>
                  <span class="weidu">未读</span>
                  <span class="intime">{{ item.pushtime }}</span>
                </div>
                <div>
                  <div class="card-title bigtitle">{{ item.mtitle }}</div>
                </div>
                <div class="borderbox">
                  <div class="card-title"
                       style="padding-top: 0;">{{ item.mdesc }}</div>
                </div>
                <div class="card-footer">
                  <span class="seedetail">查看详情</span>
                  <md-icon name="arrow-right"
                           class="seeright"
                           size="sm"></md-icon>
                </div>
              </div>
              <div class="cardelse"
                   @click="read(item,index)"
                   v-else>
                <div class="card-name">
                  <span class="yidu">已读</span>
                  <span class="intimeelse">{{ item.pushtime }}</span>
                </div>
                <div>
                  <div class="card-title bigtitle bigtitleelse">{{ item.mtitle }}</div>
                </div>
                <div class="borderbox">
                  <div class="card-title cardtitleelse"
                       style="padding-top: 0;">{{ item.mdesc }}</div>
                </div>
                <div class="card-footer">
                  <span class="seedetailelse">查看详情</span>
                  <md-icon name="arrow-right"
                           class="seeright"
                           size="sm"></md-icon>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>

    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt />
    </div>
  </div>
</template>

<script>
import { getStorage } from '@/lib/util'
import loadMorechange from '@/mixins/loadMorechange'
import config from '@/config'
import {
  QueryMessage,
  updateStatus
} from '@/api/abt/customerOperation/message/index'
import { wechatshare } from '@/lib/wechat_share'
export default {
  mixins: [loadMorechange],
  data () {
    return {
      colorprimary: '',
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      loading: false,
      isNull: false,
      wzlist: [],
      code: '',
      fllist: [
        {
          lable: '全部',
          code: '',
          ischoose: true
        },
        {
          lable: '看文章',
          code: '10',
          ischoose: false
        },
        {
          lable: '看名片',
          code: '9',
          ischoose: false
        },
        {
          lable: '看官网',
          code: '12',
          ischoose: false
        },
        {
          lable: '看产品',
          code: '7',
          ischoose: false
        },
        {
          lable: '看活动',
          code: '5',
          ischoose: false
        },
        {
          lable: '看贺卡',
          code: '4',
          ischoose: false
        },
        {
          lable: '看报告',
          code: '11',
          ischoose: false
        },
        {
          lable: '看微店',
          code: '17',
          ischoose: false
        },
        {
          lable: '看计划书',
          code: '8',
          ischoose: false
        },
        {
          lable: '出单提醒',
          code: '20',
          ischoose: false
        },
        {
          lable: '其他消息',
          code: '1,18,19',
          ischoose: false
        }
      ]
    }
  },
  created () {
    this.colorprimary = getStorage('theme', '')
    this.getData('')
  },
  mounted () {
    wechatshare('', '', '', '', '', '', true)
  },
  activated () {

  },
  methods: {
    getData () {
      // console.log(this.code)
      // console.log()
      let btagcode = this.code.split(',')
      if (btagcode[0] == '') {
        btagcode = []
      }
      let data = {
        currentPage: this.listpageNo,
        pageSize: this.listsize,
        // msgtype: '',
        btagcode: btagcode
        // otype: ''
      }
      QueryMessage(data).then(res => {
        this.wzlist =
          this.wzlist == []
            ? res.data.data.rows
            : this.wzlist.concat(res.data.data.rows)
        if (this.wzlist.length > 0) {
          this.isNull = false
        } else {
          this.isNull = true
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        // console.log('--------------请求数据中--------------')
        // console.log('请求数据中总页数' + this.listtotalPage)
        // console.log('请求数据中当前页' + this.listpageNo)
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }
        this.loadflag = true
      })
    },
    changetab (code, index) {
      this.code = code
      this.listpageNo = 1
      this.wzlist = []
      this.listFinished = false
      this.loadflag = false
      this.$refs.scrollView.finishLoadMore()
      // fllist[index].ischoose = true
      this.fllist.forEach((fitem, findex) => {
        fitem.ischoose = false
      })
      this.fllist[index].ischoose = true
      this.getData()
    },
    read (item, index) {
      let msgid = item.msgid
      updateStatus({ msgid })
        .then(res => {
          let path = item.mhttpurl.replace(config.redirect_uri, '')
          this.$router.push({ path: path })
        })
        .finally(() => {
          item.status = '1'
        })
    }
  }
}
</script>
<style scoped lang="stylus">
@import './index.styl';
</style>
